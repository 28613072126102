import { NAMESPACES } from 'constants/locales'
import { HREFLANG_LOCALE } from 'constants/prop-types'

import { logError } from 'server/utils/logger'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { TicketCreatedNotification } from 'components/help'
import { CardGridSection, QuestionsSection } from 'components/help/hub'
import DocumentHead from 'components/head'
import Header from 'components/help/hub/header/header'
import { getCommonServerSideProps } from 'utils/server'
import ContentfulService from 'services/contentful-service'
import useTranslation from 'next-translate/useTranslation'
import { help_hub } from 'components/help/hub/tracking'
import StatusWidget from 'components/status-widget'

const HelpHubPage = ({ currentUrl, availableLocales, pageContent }) => {
  const { t } = useTranslation(NAMESPACES.HELP)

  return (
    <>
      <DocumentHead
        title={t('help-hub.page-title')}
        currentUrl={currentUrl}
        availableLocales={availableLocales}
        {...pageContent.metaTags}
      />
      <Header />
      <TicketCreatedNotification />
      {pageContent?.blocks?.map(cardGrid => (
        <CardGridSection
          key={cardGrid.id}
          title={cardGrid.title}
          subtitle={cardGrid.subtitle}
          cta={cardGrid.cta}
          items={cardGrid.items}
          location={cardGrid.location}
          tracking={cardGrid.tracking}
        />
      ))}
      <QuestionsSection />
      <StatusWidget />
    </>
  )
}

export async function getServerSideProps(ctx) {
  try {
    const {
      req: { url },
      locale,
    } = ctx
    const [commonServerSideProps, pageContent] = await Promise.all([
      getCommonServerSideProps(ctx),
      ContentfulService.getPage({ url, locale }),
    ])
    const safePageContent = pageContent || {}
    return {
      props: {
        ...commonServerSideProps,
        pageContent: {
          trackingConfig: {
            viewPageSectionProps: {
              typeform_property: help_hub,
            },
          },
          ...safePageContent,
        },
      },
    }
  } catch (error) {
    logError({
      message: 'Error in help/index getServerSideProps',
      error,
      additionalInfo: { section: 'help' },
    })
    return { notFound: true }
  }
}

HelpHubPage.displayName = 'HelpHubPage'
HelpHubPage.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  availableLocales: PropTypes.arrayOf(HREFLANG_LOCALE).isRequired,
  pageContent: PropTypes.object.isRequired,
}

export default memo(HelpHubPage)

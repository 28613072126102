import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled, { css } from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'

import { BoldBodyCss, FONT_WEIGHT_BOLDER } from '../typography.styles'

import {
  SearchSurfaceStyles,
  BACKDROP_ZINDEX,
  SEARCH_INPUT_HEIGHT,
} from './search.styles'

export const ResultTextStyles = css`
  font-weight: ${FONT_WEIGHT_BOLDER};
  display: flex;
  align-items: center;
  color: ${NeutralColors.Grey600};

  ${BoldBodyCss};
`

export const StyledSearchResultWrapper = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  top: ${SEARCH_INPUT_HEIGHT - 2}px;
  left: 0;
  right: 0;
  z-index: ${BACKDROP_ZINDEX + 1};
  flex-direction: column;

  ${SearchSurfaceStyles};

  ${mediaQueries.md`
    padding: 24px 34px 32px 72px;
  `};
`
export const StyledSearchResultMessage = styled.p`
  ${ResultTextStyles};
`

import { NAMESPACES } from 'constants/locales'

import useTranslation from 'next-translate/useTranslation'

import Search from './search'
import {
  StyledHeader,
  StyledInnerHeader,
  StyledHeadline,
} from './header.styles'

const Header = () => {
  const { t } = useTranslation(NAMESPACES.HELP)

  return (
    <StyledHeader data-qa='help-hub' data-testid='help-hub'>
      <StyledInnerHeader>
        <StyledHeadline as='h1'>
          {t('help-center.hero.headline')}
        </StyledHeadline>
        <Search />
      </StyledInnerHeader>
    </StyledHeader>
  )
}

export default Header
